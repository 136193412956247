.accordion {
    overflow: hidden;

    background: white;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: black;
    }

    &.is-expanded {
        .accordion-label .accordion-caret {
            transform: rotate(90deg);
        }
    }

    .accordion-label {
        padding: 10px 0;

        cursor: pointer;
        user-select: none;

        display: flex;

        .accordion-label-text {
            margin-right: auto;
        }

        .accordion-caret {
            flex: 0 0 $lnh-l;
            height: $lnh-l;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-left: $lnh-m / 2;

            transition: transform 0.125s linear;

             @include breakpoint(sm) {
                flex: 0 0 $lnh-l-m;
                height: $lnh-l-m;

                svg {
                    height: 16px;
                }
             }
        }
    }

    .accordion-body {
        // max-height: 0;
        // transition: max-height .5s ease;

        height: 0;
        transition: height .5s ease;

        overflow: hidden;

        &.scroll {
            // overflow-y: auto;
            // overflow-x: hidden;
            overflow: hidden;
        }

        // .row > div {
        //     margin-bottom: $gutter;

        //     @include breakpoint(sm) {
        //         margin-bottom: $lnh-m-m;
        //     }
        // }
    }
}