.icon {
    svg {
        width: 1.25rem;
        height: 1.25rem;
        transform: translateY(0.15rem);

        @include breakpoint(por) {
            width: 1.125rem;
            height: 1.125rem;
            transform: translateY(0rem);
        }
    }

}