aside {
  border-right: 2px solid $blue;
  margin-bottom: 24px;

  background-color: white;
	z-index: 1;

  @include breakpoint(por) {
    margin-bottom: 0;
  }

  .wrapper > div,
  .aside-collapse > div {
      margin-bottom: $lnh-s;
  }

  .wrapper {
    height: 100%;
    padding: 0 $gutter;
    overflow: auto;
    position: relative;

    @include breakpoint(por) {
      padding: 0 $spc-v-s-m;
      overflow: visible;
    }
  }

  .aside-head {
    position: relative;
    overflow: visible;

    @include breakpoint(por) {
      position: fixed;
      top: $header-h-m;
      left: 0;
      width: calc(100% - #{$mobileBar});
      padding: 0 $spc-v-s-m;
      
      min-height: $aside-h;

      // padding-top: 2px;
      padding-bottom: $lnh-s-m / 2;

      background-color: white;
      z-index: 2;

      button {
        position: absolute;
        right: $spc-v-s-m;
        top: 0;
      }
    }
  }

  .aside-collapse {
    @include breakpoint(por) {
      // display: none;
      height: 0;
      visibility: hidden;
      margin-bottom: 0 !important;
      padding-top: 0 !important;

      &.show {
        position: fixed;
        top: calc(#{$header-h-m} + var(--aside-h));
        left: 0;

        padding-left: $spc-v-s-m;
        padding-right: $spc-v-s-m;
        margin-bottom: inherit;

        display: block;
        width: calc(100% - #{$mobileBar});
        height: calc(var(--doc-h) - (#{$header-h-m} + var(--aside-h)));

        visibility: visible;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        background-color: white;
        z-index: 12;
        transform: translate3d(0,0,12px);
      }

      &.no-overlay {
        position: relative;
        width: 100%;
        top: 0;
        height: auto;
        padding: 0;
        transform: translateZ(-1px);
      }
    }
  }
}
