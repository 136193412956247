body.cover {
    overflow: hidden;
    transform: translateY(0);

    main {
        transform: translateY(100%);
        opacity: 0;;
    }
}

.page-cover {
    position: relative;
    width: 100%;
    height: 100vh;

    z-index: 2;

    background-color: white;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .cta {
        position: absolute;
        bottom: 3.3vh;
        left: 50%;
        transform: translateX(-50%);
        line-height: 0;

        cursor: pointer;

        svg {
            transform: rotate(-90deg);
        }
    }
}