body {
  font-family: $fnt;
  font-size: $fnt-m;
  font-weight: normal;
  line-height: $lnh-m;
  letter-spacing: $lsp-m;
  font-feature-settings: 'ss04' 1;
  color: black;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  @include breakpoint(sm) {
    @include fontformat($fmt-m-m);
  }
}

p,
ul {
  margin-bottom: $lnh-m;

  @include breakpoint(sm) {
    margin-bottom: $lnh-m-m;
  }
}

i,
em {
  letter-spacing: 0;
}

a {
  color: $blue;
  text-decoration: none;

  transition: color .2s;

  &:hover,
  &:focus,
  &:active {
    color: black;
    text-decoration: none;
  }

  &:has(.icon) {
    display: flex;

    .icon {
      margin-right: .5rem;

      @include breakpoint(por) {
        margin-right: .35rem;
      }
    }

    &:hover {
      svg circle,
      svg path {
        fill: black;
      }
    }
  }
}

h1, h2, h3, h4 {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;

  font-feature-settings: 'ss04' 0;

  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}


.fnt,
[class^="fnt"] {
  font-family: $fnt;
}

.fnt-s {
  @include fontformat($fmt-s);

  p {
    margin-bottom: $lnh-s;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-s-m);

    p {
      margin-bottom: $lnh-s-m;
    }
  }
}

.fnt-xs {
  @include fontformat($fmt-xs);

  p {
    margin-bottom: $lnh-xs;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-xs-m);

    p {
      margin-bottom: $lnh-xs-m;
    }
  }
}

.fnt-m {
  @include fontformat($fmt-m);

  p {
    margin-bottom: $lnh-m;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-m-m);

    p {
      margin-bottom: $lnh-m-m;
    }
  }
}

.fnt-l {
  @include fontformat($fmt-l);
  font-feature-settings: 'ss04' 0;

  p {
    margin-bottom: $lnh-l;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-l-m);

    p {
      margin-bottom: $lnh-l-m;
    }
  }
}

.fnt-xl {
  @include fontformat($fmt-xl);
  font-feature-settings: 'ss04' 0;

  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;

  p {
    margin-bottom: $lnh-xl;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-xl-m);

    p {
      margin-bottom: $lnh-xl-m;
    }
  }
}

.bold,
strong {
  font-weight: bold;
  letter-spacing: 0 !important;
}

.blue {
  color: $blue;
}

.center {
  text-align: center
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.italic {
  font-style: italic;
}

.color-blue {
  color: $blue;
}

.color-grey {
  color: $grey;
}

.headings {
  h2 {
    @include fontformat($fmt-xl);
    font-weight: bold;
    letter-spacing: 0;

    margin-bottom: $lnh-xl / 2;

    @include breakpoint(por) {
        @include fontformat($fmt-xl-m);
        letter-spacing: 0;
        margin-bottom: $lnh-xl-m / 2;
    }
  }

  h3 {
    @include fontformat($fmt-l);
    font-weight: bold;
    letter-spacing: 0;

    margin-bottom: $lnh-l / 2;

    @include breakpoint(por) {
        @include fontformat($fmt-l-m);
        letter-spacing: 0;
        margin-bottom: $lnh-l-m / 2;
    }
  }

  h4 {
    font-weight: bold;
    letter-spacing: 0;
  }
}