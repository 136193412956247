.body-wrapper {
  position: relative;
  min-height: calc(100% - #{$footer-h});
}

main {
  display: flex;
  padding-top: $header-h;

  min-height: calc(100% - #{$footer-h});

  @include breakpoint(por) {
    display: block;
    padding-top: $header-h-m;
  }

  .content {
    flex: 0 0 75%;
    // width: 75%;
    // margin-left: 25%;
  
    padding-bottom: $spc-v-l;//$lnh-l;
  
    @include breakpoint(por) {
      width: 100%;
      margin-left: 0;
  
      // padding-bottom: $lnh-l-m;
      padding-top: var(--aside-h);//$aside-h
    }
  }
}

aside {
    // position: fixed;
    // top: $header-h;
    // left: 0;
    // width: 25%;
    
    flex: 0 0 25%;

    // --diff: 0px;
    // height: calc(100vh - #{$header-h}); // IE11
    // height: calc(100vh - #{$header-h} - var(--diff));

    // overflow: hidden;

    @include breakpoint(0,1000px) {
      word-break: break-all;
    }

    @include breakpoint(por) {
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
      overflow: visible;
    }
}

// .logo-scroll-indicator {
//   position: absolute;
//   bottom: -20px;
//   left: 0;
//   height: 10px;
//   width: 50%;
//   background-color: red;
// }