&.page-product-vertical {
    &:hover {
        .teaser-image {
            &:before {
                background-color: #0003E233;
            }
                
            img {
                filter: invert(0);
            }
            svg {
                path {
                    fill: $blue;
                }
            }
        }

        .teaser-excerpt {
            color: black;
        }
    }

    .teaser-wrapper.flex {
        flex-flow: column;

        padding: $lnh-xs / 2;

        @include breakpoint(por) {
            display: flex;
            // height: 250px;
        }
    }

    .teaser-image {
        line-height: 0;
        
        @include breakpoint(por) {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            display: block;
            position: relative;

            height: 0;
            width: 100%;

            padding-top: 73%;

            background-color: #D9D9D9;
            border-radius: 12px;

            opacity: 1;
            z-index: 0;

            mix-blend-mode: normal;

            @include breakpoint(por) {
                padding-top: 40%;
            }
        }

        figure, 
        img,
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            height: 80%;
        }
    }

    .teaser-body {
        margin: $lnh-xs / 4 0;
        height: $lnh-xs * 3;

        overflow: hidden;

        @include breakpoint(por) {
            margin: $lnh-xs-m / 4 0;
            height: $lnh-xs-m * 3;
        }

        .teaser-title {
            margin-bottom: $lnh-xs / 4;

            @include breakpoint(por) {
                margin-bottom: $lnh-xs-m / 4;
            }
        }
    }
}