&.page-subpage {
    flex: 0 0 50%;

    margin-right: 0;

    @include breakpoint(tmd) {
        flex: 0 0 100%;
    }

    @include breakpoint(md) {
        flex: 0 0 100%;
    }

    @include breakpoint(por) {
        flex: 0 0 100%;
        
    }

    &:hover {
        .teaser-image {
            img {
                filter: none;
            }
        }
    }

    .teaser-image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 15px;

            @include breakpoint(por) {
                border-radius: 10px;
                filter: none;
            }
        }
    }
}