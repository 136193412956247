footer {
    height: $footer-h;

    transform: translateZ(1px);

    // padding-top: 24px;

    .footer-wrapper {
        display: flex;
        flex-flow: column;

        height: 100%;

        padding: $spc-v-s;
        // padding-right: 0;
        padding-bottom: $spc-v-s / 1.5;

        background-color: $blue;
        color: white;

        @include breakpoint(por) {
            padding: $spc-v-s-m;
            padding-bottom: $spc-v-s-m * 0.75;
            padding-right: 0;
        }
    }

    .footer-social {
        display: flex;

        margin-bottom: auto;

        a {
            margin-right: 12px;

            @include breakpoint(por) {
                margin-right: $spc-v-s-m;
            }
        }

        svg {
            width: 25px;
            height: 25px;
        }
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
    }

    nav#meta-nav {
        flex: 1;

        display: flex;
        align-items: flex-end;

        @include breakpoint(por) {
            width: 100%;
        }

        ul {
            list-style: none;
            
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            width: 100%;
            max-width: 530px;

            margin-bottom: 0;

            padding-right: $gutter;

            @include breakpoint(por) {
                width: 100%;
                max-width: none;
                justify-content: space-between;
                padding-right: 0;
            }

            li {
                // margin-right: 50px;

                @include breakpoint(por) {
                    margin-right: 0;
                }

                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }

    .footer-logo {
        flex: 0 1 530px;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(0,1000px) {
            display: none;
        }

        svg {
            width: 100%;
            max-width: 530px;
            height: auto;
            transform: translateY(2px);
        }
    }
}
