&.page-team {
    &:hover {
        .teaser-wrapper {
            border: 1px solid $blue;

            @include breakpoint(por) {
                border: 1px solid white;
            }
        }
    }

    .teaser-wrapper {
        padding: $gutter / 2;
        border: 1px solid white;

        @include breakpoint(por) {
            padding: 0;
        }
    }
    
    &.rel-col-2 {
        padding: 0;

        @include breakpoint(por) {
            flex: 0 0 50%;
            max-width: 50%;

            &:nth-child(even) {
                padding-left: $spc-v-s-m / 2;
            }
            
            &:nth-child(odd) {
                padding-right: $spc-v-s-m / 2;
            }
        }	
        
        @include breakpoint(1600px,0) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .teaser-image figure .image-wrapper {
        padding-top: 75%;
    }

    h2 {
        padding-bottom: 0;
        padding-top: $lnh-s / 4;

        @include breakpoint(por) {
            padding-top: 0;
        }
    }

    .teaser-body p {
        margin-bottom: 0;
    }
}