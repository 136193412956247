&.page-project {
    margin-bottom: $spc-v-s * 0.75;
    padding: 0 $spc-v-s * 0.75 / 2;

    @include breakpoint(por) {
        margin-bottom: 0;
        padding: 0;
    }

    .teaser-copy {
        padding-top: $lnh-s / 2;       
        padding-bottom: $lnh-s / 2;

        hyphens: auto;
        
        @include breakpoint(por) {
            padding-top: $lnh-s-m / 2;
            padding-bottom: $lnh-s-m / 2;

            @include fontformat($fmt-m-m);
        }
    }

    h2,
    h3,
    h4 {
        padding-bottom: 0;
    }

    .teaser-wrapper {
        padding: $gutter / 2;
        border: 1px solid $blue;
    }

    .badge.finished {
        position: absolute;
        bottom: $gutter / 2;
        right: $gutter / 2;

        padding: 2px 10px;

        background-color: rgba($blue, .7);
        color: white;

        border-radius: 10px;
    }
}