&.page-research-theme {
	text-align: center;

	.teaser {
		&-wrapper {
			border: none;
		}

		&-image--page-research-theme {
			margin-bottom: $lnh-xs / 4;

			@include breakpoint(sm) {
				margin-bottom: $lnh-xs-m / 4;
			}

			& > div {
				line-height: 0;
			} 

			.hover {
				display: none;
			}

			&:hover {
				.default {
					display: none;
				}
				.hover {
					display: block;
				}
			}
		}

		&-title--page-research-theme {
			word-break: break-word;
			
			@include breakpoint(sm) {
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
}