.mobile {
  display: none !important;

  @include breakpoint(por) {
  	display: block !important;
  }
}

.desktop {
  display: block !important;
  
  @include breakpoint(por) {
  	display: none !important;
  }
}

.desktop-flex {
	display: flex !important;

	@include breakpoint(por) {
		display: none !important;
	}
}

.desktop-inline-flex {
	display: inline-flex !important;

	@include breakpoint(por) {
		display: none !important;
	}
}

.desktop-inline {
	display: inline !important;

	@include breakpoint(por) {
		display: none !important;
	}
}

// .sticky {
//   position: sticky;
//   top: $header-h;
// }

.noselect {
  user-select: none;
}

.hidden {
  display: none;
  visibility: hidden;
}

button,
.button {
  border-radius: 2em;
  background-color: $blue;
  color: white;
  font-size: $fnt-s;
  line-height: 37px;
  padding: 0 1em;
  
  font-weight: bold;
  letter-spacing: 0;

  @include breakpoint(por) {
    display: inline-block;
    font-size: $fnt-s-m;
    line-height: 1.65em;
    font-weight: normal;
  }

  &:hover,
  &:focus {
    color: white;
  }
}

.ui-toggle {
  display: flex;
  align-items: center;

  cursor: pointer;

  #switch {
      position: relative;

      height: 1px;
      width: $lnh-m;

      margin: 0 $lnh-s / 3;
      margin-top: -5px;

      background-color: black;

      &:before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: black;

          position: absolute;
          left: 0;
          top: 1px;

          transform: translate(calc(#{$lnh-m} - 100%),-50%);
          transition: transform .25s ease;
      }
  }
}