.dialog.cookies {
    bottom: $spc-v-s * 3;
    left: 50%;
    transform: translate3d(-50%,0,20px);

    max-width: calc((75vw - 35px) / 6 * 4);
    
    background-color:rgb(179,179,246);
    color: black;

    @include breakpoint(por) {
        bottom: $spc-v-s-m * 2;
        left: $spc-v-s-m;
        transform: none;
        max-width: calc(100% - #{$spc-v-s-m * 2});
    }
}