.teaser {
    margin-bottom: $spc-v-s;

    @include breakpoint(por) {
        margin-bottom: $spc-v-s-m * 1.25;
    }

    a,
    .teaser-wrapper {
        &:not([download]) {
            color: black;
        }

        &:hover,
        &:focus {
            color: $blue;

            .teaser-image {
                &:before {
                    opacity: .25;

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        opacity: .25;
                    }
                }
            }
        }
    }

    h2 {
        padding-bottom: $lnh-s / 2;

        @include breakpoint(por) {
            padding-bottom: $lnh-s-m / 2;
        }
    }

    .teaser-image {
        position: relative;

        @include breakpoint(por) {
            margin-bottom: $spc-v-s-m / 2;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $blue;
            // mix-blend-mode: screen;
            border-radius: 15px;
            
            opacity: 0;
            // transition: opacity .125s;

            z-index: 1;

            pointer-events: none;

            @include breakpoint(por) {
                border-radius: 10px;
            }
        }

        figcaption {
            display: none;
        }
    }

    &.border-bottom {
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: black;
            margin-top: $spc-v-s;

            @include breakpoint(por) {
                margin-top: $spc-v-s-m;
            }
        }    
    }

    .teaser-wrapper {
        border: 1px solid $blue;
        border-radius: 15px;

        @include breakpoint(por) {
            border-radius: 10px;
        }

        &.flex {
            display: flex;

            @include breakpoint(por) {
                display: block;
            }

            .teaser-left {
                flex: 0 0 40%;
                padding: $spc-v-s;
                padding-right: $spc-v-s / 2;

                @include breakpoint(por) {
                    padding: $spc-v-s-m;
                    padding-bottom: 0;
                }
            }
    
            .teaser-right {
                flex: 0 0 60%;
                padding: $spc-v-s;
                padding-left: $spc-v-s / 2;

                // display: flex;
                // flex-flow: column;

                @include breakpoint(por) {
                    padding: $spc-v-s-m;
                    padding-top: $spc-v-s-m / 4;
                }

                .teaser-excerpt {
                    &.hidden {
                        display: none !important;
                    }
                }
            }
        }
    }

    @import 'teaser/archive-item';
    @import 'teaser/page-product';
    @import 'teaser/page-product-vertical';
    @import 'teaser/page-event';
    @import 'teaser/page-press';
    @import 'teaser/page-project';
    @import 'teaser/page-project-references';
    @import 'teaser/page-team';
    @import 'teaser/page-news';
    @import 'teaser/page-publication';
    @import 'teaser/page-research-group';
    @import 'teaser/page-research-theme';
    @import 'teaser/page-subpage';
}

.teaser-wrapper--page-product-vertical {
    display: flex;

    // margin-left: -$gutter / 2;
    // margin-right: -$gutter / 2;

    .teaser.page-product-vertical {
        flex: 0 0 25%;

        padding: 0 $gutter / 2;
        
        @include breakpoint(por) {
            flex: 0 0 100%;
            padding: 0;
        }
    }
}