&.page-press {
    h2 {
        padding-top: 0;

        @include breakpoint(por) {
            padding-top: $lnh-s-m / 2;
        }
    }

    a:hover, 
    a:focus {
        color: black;
    }
}