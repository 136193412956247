&.page-project-references {
    display: flex;

    margin-bottom: $lnh-m / 2;

    &:before {
        flex: 0 0 18px;
    }

    a {
        color: $blue;

        &:hover {
            color: black;
        }
    }
}