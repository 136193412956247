.dialog {
    position: fixed;

    width: calc(100% - 10px - #{$spc-v-s-m * 2});
    max-width: 500px;

    background: rgba(255, 255, 255, 0.8);
    border: 1px solid $blue;
    border-radius: 15px;

    padding: $spc-v-s-m;

    display: flex;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: flex-end;//space-between;

    opacity: 0;
    pointer-events: none;

    transition: opacity .25s;

    z-index: 100;

    @include breakpoint(por) {
        left: $spc-v-s-m;
        border-radius: 10px;
    }

    &.visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 100;
    }

    .dialog-body {
        flex: 0 0 100%;
        // padding-bottom: $lnh-m * 2;
    }

    .dialog-button {
        display: flex;
        align-items: flex-end;

        margin-left: $spc-v-s / 2;

        @include breakpoint(por) {
            margin-left: $spc-v-s-m / 2;
        }
    }
}