&.page-research-group {
	
	p:last-of-type {
		margin-bottom: 10px;
	}

	.teaser-wrapper {
		display: flex;
		gap: $lnh-m;

		padding: $spc-v-m / 2;

		.teaser-image--page-research-group {
			flex: 0 0 78px;
		}
	}

	&:hover {
		.teaser-wrapper {
			background-color: rgba(0, 3, 226, 0.1);
			color: black;
		}
	}
}