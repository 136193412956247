&.page-product {
    margin-right: $gutter / 2;
    padding: 0 $spc-v-s / 2;

    @include breakpoint(por) {
        padding: 0;
        margin-right: 0;
    }

    &:hover {
        .teaser-wrapper.flex {
            background-color: $blue;
            color: white;
        }

        .teaser-image {
            &:before {
                opacity: 0;
            }
            
            img {
                filter: invert(1);
            }
            svg {
                path {
                    fill: white !important;
                }
            }
        }
    }

    .teaser-wrapper.flex {
        height: 132px;
    
        @include breakpoint(por) {
            height: 100px;
            display: flex;
        }

        .teaser-left {
            flex: 0 0 25%;

            padding: $spc-v-s / 2;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .teaser-right {
            flex: 0 0 75%;

            padding: $spc-v-s / 2;

            @include breakpoint(por) {
                // padding-top: 0;
                padding: $spc-v-s-m / 2;
            }

            h2 {
                max-height: $lnh-m * 2;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            .teaser-excerpt {
                height: $lnh-s * 2;

                @include breakpoint(por) {
                    height: auto;
                }

                p {
                    margin-bottom: $lnh-s / 2;

                    @include breakpoint(por) {
                        margin-bottom: $lnh-s-m / 2;
                    }
                }
            }
        }
    }

    .teaser-image {
        width: 100%;
        height: 100%;

        line-height: 0;

        @include breakpoint(por) {
            display: flex;
            justify-content: center;

            margin-bottom: 0;
        }

        figure .image-wrapper {
            padding-top: 100%;
            background-color: transparent;
        }

        svg {
            width: 100%;
            height: 100%;
            max-width: 200px;
            
            path,
            circle,
            polygon,
            rect {
                fill: $blue !important;
            }
        }
    }
}