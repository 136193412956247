figure .image-wrapper {
    &.ratio {
        position: relative;
    
        height: 0;
        padding-top: 56.25%; // IE11
        padding-top: calc(var(--height) / var(--width) * 100%);
      
        background-color: rgb(220,220,220);
    
        border-radius: 15px;
    
        @include breakpoint(por) {
            border-radius: 10px;
        }
    
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
    
            background-color: white;
        }
    }

    img {
        border-radius: 15px;

        @include breakpoint(por) {
            border-radius: 10px;
        }
    }
}

figcaption {
    width: 100%;

    color: $blue;

    padding: $lnh-xs 0;

    @include breakpoint(por) {
        padding: $lnh-xs-m / 2 0;
    }
}