&.page-event {
    h2 {
        padding: $lnh-s / 2 0;

        @include breakpoint(por) {
            padding: $lnh-s-m / 2 0;
        }
    }

    .teaser-image {
        figure .image-wrapper {
            padding-top: 75.23%
        }
    }

    .teaser-date {
        display: flex;
        justify-content: space-between;
    }
}