form {
    input[type="search"] {
        letter-spacing: 0;
        
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 1em;
            width: 1em;
            border-radius: 50em;
            background-image: url('/assets/images/filter-times.svg');	
            background-size: contain;
            opacity: 0;
            pointer-events: none;
            margin-right: 10px;
        }
        
        &:focus::-webkit-search-cancel-button {
            opacity: 1;
            pointer-events: all;
        }
    }
}