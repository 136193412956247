a.download,
a[download] {
  &:not(.button) {
    margin-bottom: $lnh-s / 2;

    @include breakpoint(por) {
      margin-bottom: $lnh-s-m / 4;
    }

    &:hover {
      svg circle {
        fill: black;
      }
    }
  }

  .dialog.download {
    top: 50%;
    left: 50%;
    height: 177px;
    transform: translate(-50%,-50%);

    @include breakpoint(por) {
      left: $spc-v-s-m;
      transform: translateY(-50%);
      max-width: calc(100% - #{$spc-v-s-m * 2});
    }
  }
}