nav#main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    transform: translateX(100%);
    transition: transform 0s linear 1s, width 0s linear 1s;

    overflow: hidden;

    z-index: 13;

    &.show {
        transform: translateX(0) translateZ(20px);
        width: 100%;

        overflow-y: auto;
        overflow-x: hidden;

        transition: transform 0s, width 0s;

        .nav-bg {
            // background-color: rgba(0,0,0,.5);
            opacity: 1;
        }

        .nav-menu {
            transform: translateX(0);
        }
    }

    .nav-menu {
        position: relative;
        // top: 0;
        // right: 0;

        width: calc(75% + 2px);//calc(75% + #{$gutter / 2});
        height: 100%;

        margin-left: auto;
        padding: $spc-v-s;

        background-color: $blue;
        color: white;

        transform: translateX(100%);
        transition: transform .5s ease 0.01s;

        overflow: auto;

        @include breakpoint(por) {
            width: 100%;
            padding: $spc-v-s-m;
        }
    
        a {
            color: white;
            text-decoration: none;
        }
    
        button.nav-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 80px;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;
            line-height: 0;

            @include breakpoint(por) {
                width: 60px;
                height: 60px;
            }

            svg {
                width: 20px;
                height: 20px;
                
                @include breakpoint(por) {
                    width: 15px;
                    height: 15px;    
                }
            }
        }
    }

    .nav-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: -1;

        // background-color: rgba(0,0,0,.5);
        opacity: 0;
        transition: opacity 1s;

        .header-logo {
            position: absolute;
            left: $spc-v-s;
            top: $spc-v-s;

            z-index: 2;

            @include breakpoint(por) {
                left: $spc-v-s-m;
                top: $spc-v-s-m;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;

            opacity: 0;
            transition: opacity .5s, z-index 0s linear .5s;

            &.current {
                opacity: 1;
            }

            &.active {
                opacity: 1;
                z-index: 1;
                transition: opacity .5s, z-index 0s;
            }
        }
    }
  }