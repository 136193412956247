header {
  z-index: 20;

  a:hover {
    color: $blue;

    svg {
      path {
        fill: $blue;
      }
    }
  }

  .header-sticky {
    position: fixed;
    top: 0;
    left: 0;

    height: $header-h;
    width: 100%;

    // padding: $spc-v-s 0;
    padding-right: 5px; 

    display: flex;

    z-index: 10;

    @include breakpoint(por) {
      width: calc(100% - #{$mobileBar});
      height: $header-h-m;

      // padding: $spc-v-s-m;
      padding-right: 5px;

      background-color: white;
    }

    .header-logo {
      flex: 0 0 24%;
      padding-left: $spc-v-s;
      padding-top: $spc-v-s;
      background-color: white;

      @include breakpoint(por) { 
        padding-left: $spc-v-s-m;
        padding-top: $spc-v-s-m;
      }
    }
  }

  .header-logo {
    line-height: 0;

    svg {
      @include breakpoint(por) {
        // height: 100%;
        // width: auto;
        width: 100px;
        height: 34px;
      }
    }
  }

  .header-controls {
    display: flex;
    margin-left: auto;
    margin-top: $spc-v-s;

    height: 40px;

    padding: 9px 13px;

    border-radius: 2em;

    @include breakpoint(por) {
      margin-top: $spc-v-s-m - 3;
    }

    &.bg-white {
      background-color: white;
    }

    & > div {
      margin-left: $spc-v-s;

      &.search {
        margin-left: 0;

        svg {
          opacity: 0;

          @include breakpoint(por) {
            opacity: 1;
          }
        }
      }

      &#language-switch {
        font-size: 18px;
        line-height: 23px;
        width: 24px;

        @include breakpoint(por) {
          line-height: 21px;
        }

        a {
          text-decoration: none;
        }
      }

      button {
        line-height: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
      }
    }
  }

  .header-search {
    position: absolute;
    right: 98px;
    top: 20px;//24px;

    display: flex;
    width: 32px;
    
    // line-height: 30px;
    margin: 4px 0;

    // background-color: white;
    // border: 1px solid $blue;
    border: 1px solid transparent;
    border-radius: 2em;

    // opacity: 0;
    // transition: opacity .25s linear .65s, width .5s ease .2s;
    transition: background-color .25s linear .65s, border-color .25s linear .65s, width .5s ease .2s;

    pointer-events: none;

    @include breakpoint(por) {
      right: $spc-v-s-m;
      top: $header-h-m - 3px;

      margin: 0;

      width: calc(100% - #{$spc-v-s-m * 2});
      // width: 100%;
      // padding: 0 $spc-v-s-m;

      opacity: 0;
      transition: opacity .25s, width .5s ease;
      // transition: background-color .25s, border-color .25s, width .5s;
    }

    &.open {
      opacity: 1;
      pointer-events: auto;

      border-color: $blue !important;
      background-color: white !important;

      width: calc(75vw - (25px + 13px + 74px));
      transition: background-color .25s, border-color .25s, width .5s ease;

      @include breakpoint(por) {
        width: calc(100% - #{$spc-v-s-m * 2});
      }

      // form {
      //   //content (plus scrollbar), padding header, padding controls, control items, search icon
      //   width: calc(75vw - (30px + 13px + 81px + 30px));

      //   @include breakpoint(por) {
      //     width: 100%;
      //   }
      // }
    }

    .header-search-inner {
      display: flex;
      width: 32px;

      border: 1px solid $blue;
      border-radius: 2em;

      line-height: 30px;

      transition: width .5s ease .2s;
    }
    
    form {
      line-height: 0;
      display: flex;
      align-items: center;

      width: 100%;

      // width: 0;
      // transition: width .5s ease .2s;

      // @include breakpoint(por) {
      //   width: 100%;
      //   transition: width .5s;
      // }

      input {
        width: 100%;

        color: $blue;
        font-style: italic;

        padding: 0 10px;
      }
    }

    .search-icon {
      width: 30px;
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;

      flex: 0 0 auto;

      @include breakpoint(por) {
        width: 37px;
      }
      
      svg {
        @include breakpoint(por) {
          width: 19px;
          height: 19px;
          margin-top: -1px;
        }
      }
    }
  }

  ul {
    list-style: none;
  }
}