* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

html {
  // Always show the vertical scrollbar to prevent `jumps`
  // of page content after navigating from a very short page
  // that does not scroll to a long pager
  overflow-y: scroll;

  // Setting this to `default` prevents the cursors from
  // changing when hovering blocks of text or whitespace,
  // which is less distracting
  cursor: default;

  // Neccessary for sticks footer.
  position: relative;
  min-height: 100%;

  // Prevent adjustments of font size after orientation
  // changes in IE and iOS.
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;

  &.msie {
    body {
      overflow-x: hidden;
    }
  }

  &.msie,
  &.firefox {
    body {
      padding-right: $spc-v-s;
      scrollbar-color: white;
      
      &:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        width: $spc-v-s;
        height: 100%;
        background-color: $blue;
        z-index: 22;
      }

      header .header-sticky{
        width: calc(100% - #{$spc-v-s});
      }

      nav#main-menu .nav-menu button.nav-close {
        right: $spc-v-s;

        @include breakpoint(por) {
          right: 0;
        }
      }
    }
  }
}

html, body {
  height: 100%;
  width: 100%;

  // overflow-x: hidden;
}

body {
  
  &.disable-scroll {
    position: fixed;
    width: 100%;
  }

  // scrollbar-width: $spc-v-s;
  // scrollbar-color: $blue;

  &::-webkit-scrollbar {
    width: $spc-v-s;
    background-color: $blue;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border: 3px solid $blue;
    border-radius: 2em;
  }

  @include breakpoint(por) {
    padding-right: $mobileBar;
    
    &:after {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      width: $mobileBar;
      height: 100%;
      background-color: $blue;
      z-index: 22;
    }
  }
}

figure {
  line-height: 0;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  figcaption {
    @include fontformat($fmt-xs);

    @include breakpoint(por) {
      @include fontformat($fmt-xs-m);
    }   
  }
}

img {
  width: 100%;
}

@include breakpoint(por) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}
