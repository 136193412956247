&.page-publication {
    .teaser-wrapper {
        padding: $spc-v-m;

        @include breakpoint(sm) {
            padding: $spc-v-s-m;
        }
    }

    .title {
        &::before {
            content: '';
            display: inline-block;
            width: 0.9em;
            height: 0.9em;
            background-color: $blue;    
            border-radius: 50%;
            vertical-align: -2px;
            margin-right: 2px;
        }

        a.author {
            &.active {
                background: rgba(255,255,0,.25);
            }
        }

        h2 {
            display: inline;
            font-size: $fnt-m;
            font-weight: normal;        
        }

        a:not(.author) {
            color: $blue;
        }

        p {
            display: inline;
        }
    }

    .buttons {
        margin-top: 1em;

        a.button {
            color: white;
            font-weight: normal;
            font-size: $fnt-xs;

            margin-bottom: $lnh-s / 4;
        }
    }

    .abstract {
        margin-top: 1em;

        &.accordion:after {
            display: none;
        }

        .accordion-label {
            text-decoration: underline;
            padding: 0;
        }

        p {
            margin-bottom: 0;
            padding-top: .5em;
        }
    }
}