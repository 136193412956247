&.page-news {
    h2 {
        padding: $lnh-s / 4 0;
    }

    a {
        color: $blue;

        &:hover,
        &:focus {
            color: $black;
        }
    }

    .header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .body-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: space-between;

        height: 100%;
    }

    .body-header {
        margin-bottom: auto;

        h2 {
            margin-bottom: $lnh-s / 2;

            @include breakpoint(por) {
                margin-bottom: $lnh-s-m / 2;
            }
        }
    }
}